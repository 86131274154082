/************ Custom Fonts from Assets folder ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css - using throughout page(html/body) ************/
* {
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400; /* 400 is considered normal */
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important; /** !important incase bootstrap overwrites **/
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 0;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212; /* once scrolled, background changes to dark grey */
}
a.navbar-brand {
  width: 17%;
}
@media (max-width: 768px) {
  a.navbar-brand {
    width: 25%;
  }
  .navbar-text {
    margin-right: 1rem;
  }
}

@media (max-width: 480px) {
  a.navbar-brand {
    width: 35%;
  }
  .navbar-text {
    margin-right: 0.75rem;
  }
}

.ms-auto {
  display: inline-flex;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
}

.social-icon a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 50%;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.social-icon a::before {
  content: "";
  width: 3rem;
  height: 3rem;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
/* .invert-color {
  filter: invert(1);
} */
.navbar-text button { /* lets connect button */
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}


/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
@media (max-width: 768px) {
  .banner h1  {
    font-size: 44px;
  }
}

@media (max-width: 480px) {
  .banner h1  {
    font-size: 32px;
  }
}

.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  padding: 15px 0 20px 0px;
  position: relative;
  align-items: center;
}
.skill-subheading {
  max-width: auto;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 25px;
}
@media (max-width: 768px) {
  .skill-subheading {
    justify-content: stretch;
    width: 100%; 
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 480px) {
  .skill-subheading {
    justify-content: stretch;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.skill-bx {
  background: #151515;
  padding-top: 25px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-bottom: 20px;
  text-align: center;
  border-radius: 50px;
}
@media (max-width: 768px) {
  .skill-bx {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .skill-bx {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.skill h1 {
  text-align: center;
  padding-top: 10px;
  margin-top: 0;
}
.skill h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  color: white;
}
.skill h3 {
  font-size: 18px;
  margin: 5px;
  padding-bottom: 5px;
  padding-top: 15px;
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 35px 0;
}
.skill-slider {
  position: relative;
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (max-width: 768px) {
  .skill-slider {
    margin: 0;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

@media (max-width: 480px) {
  .skill-slider {
    margin: 0;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}
.item {
  padding-bottom: 10px;
  margin-bottom: 50px;
}
.skill2 {
  width: 85%;
  margin: auto;
  color: #fff;
  padding-bottom: 0px;
  padding-top: 0;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  border-radius: 10px;
  position: relative;
}
.skill2 p {
  margin: 0 0 0 0;
  padding: 0;
  justify-content: center;
}
.bar {
  background: #353b48;
  display: block;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 0 15px 0 15px;
}
.bar:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.bar span {
  height: 20px;
  float: left;
  background: linear-gradient(
    135deg,
    rgba(185, 0, 236, 0.584) 0%,
    rgba(103, 182, 252, 0.594) 100%
  );
}
.skill-html {
  width: 90%;
  animation: skill-html 3s;
}
@keyframes skill-html {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
.handlebars {
  width: 75%;
  animation: handlebars 3s;
}
@keyframes handlebars {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}
.css {
  width: 90%;
  animation: css 3s;
}
@keyframes css {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
.javascript {
  width: 70%;
  animation: javascript 3s;
}
@keyframes javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
.react {
  width: 65%;
  animation: react 3s;
}
@keyframes react {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
.ruby {
  width: 20%;
  animation: ruby 3s;
}
@keyframes ruby {
  0% {
    width: 0%;
  }
  100% {
    width: 20%;
  }
}
.python {
  width: 60%;
  animation: python 3s;
}
@keyframes python {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}
.nodejs {
  width: 60%;
  animation: nodejs 3s;
}
@keyframes nodejs {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}
.ep {
  width: 50%;
  animation: ep 3s;
}
@keyframes ep {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
.php {
  width: 25%;
  animation: php 3s;
}
@keyframes php {
  0% {
    width: 0%;
  }
  100% {
    width: 25%;
  }
}
.Csharp {
  width: 15%;
  animation: Csharp 3s;
}
@keyframes Csharp {
  0% {
    width: 0%;
  }
  100% {
    width: 15%;
  }
}
.java {
  width: 35%;
  animation: java 3s;
}
@keyframes java {
  0% {
    width: 0%;
  }
  100% {
    width: 35%;
  }
}
.mongo {
  width: 50%;
  animation: mongo 3s;
}
@keyframes mongo {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
.dynamo {
  width: 40%;
  animation: mongo 3s;
}
@keyframes mongo {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}
.postgreSQL {
  width: 40%;
  animation: postgreSQL 3s;
}
@keyframes postgreSQL {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}
.mySQL {
  width: 20%;
  animation: mySQL 3s;
}
@keyframes mySQL {
  0% {
    width: 0%;
  }
  100% {
    width: 20%;
  }
}
.amazon {
  width: 45%;
  animation: amazon 3s;
}
@keyframes amazon {
  0% {
    width: 0%;
  }
  100% {
    width: 45%;
  }
}
.azure {
  width: 15%;
  animation: azure 3s;
}
@keyframes azure {
  0% {
    width: 0%;
  }
  100% {
    width: 15%;
  }
}
.pc {
  width: 85%;
  animation: pc 3s;
}
@keyframes pc {
  0% {
    width: 0%;
  }
  100% {
    width: 85%;
  }
}
.scrum {
  width: 40%;
  animation: scrum 3s;
}
@keyframes scrum {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}
.waterfall {
  width: 40%;
  animation: waterfall 3s;
}
@keyframes waterfall {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}
.webflow {
  width: 75%;
  animation: webflow 3s;
}
@keyframes webflow {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}
.automation {
  width: 25%;
  animation: automation 3s;
}
@keyframes automation {
  0% {
    width: 0%;
  }
  100% {
    width: 25%;
  }
}
.ml {
  width: 20%;
  animation: ml 3s;
}
@keyframes ml {
  0% {
    width: 0%;
  }
  100% {
    width: 20%;
  }
}
.background-image-left {
  background-color: #000000;
  top: 14%;
  position: absolute;
  bottom: 0;
  width: 40%;
  opacity: 0%;
  z-index: -4;
}
.background-image-right {
  background-color: #000000;
  top: -190px;
  position: absolute;
  bottom: 0;
  width: 40%;
  right: 0;
  rotate: 90deg;
  z-index: -4;
  opacity: 0%;
  mix-blend-mode: screen;
}
/************ Projects Css ************/
.project {
  padding: 75px 0;
  position: relative;
  background-color: black;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 90%;
}
.project .nav.nav-pills {
  margin: 0;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.3333%;
  border-radius: 50px;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100%;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: 900px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.row {
  display: flex;
}

/************ Contact Form Css ************/
.contact {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  margin: auto;
  padding: 0 0 15px 0;
  background-image: url("./assets/img/footer-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer img {
  width: 30%;
}
@media (max-width: 768px) {
  .footer img {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .footer img {
    width: 70%;
  }
}
.social-icon-layout {
  text-align: right;
  margin-right: 25px;
  width: auto;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 1rem;
  display: flex;
}

.email2 {
  text-align: right;
  margin-right: 20px;
}